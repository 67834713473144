import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../scss/page.scss'
import Section from "../components/section/section";
import {graphql, useStaticQuery} from "gatsby";
import {get} from "lodash";
import Table from "react-bootstrap/Table";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Privacy Policy | Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
      disallowIndexing: true,
    }
  }
  render() {
    const { data } = this.props
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return (
      <Layout containerClassNames={`page privacy-policy`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="privacy-policy py-2 px-0">
          <div className="row">
            <div className='col-10 col-md-6 mt-5 mx-auto my-md-5'>
              <h1 className="text-lg-center text-md-left">Privacy Policy</h1>
              <br/>
              <div className="row">
                <div className="col-md-12">
                  <br/>
                  <p>Therapetic (<a href="/">https://therapetic.org/</a>) (“Company” “we” or “us” or “our”) respects the privacy of its users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit the Therapetic website (individually and collectively, the “Service”).</p>
                  <br/>
                  <p>Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.</p>
                  <br/>
                  <h2>Your Rights</h2>
                  <br/>
                  <p>Under the California Consumer Privacy Act (“CCPA”), California residents have certain rights with respect to the processing and use of their personal information. We will extend these rights to <strong>ALL</strong> users, within the limitations of this privacy policy. These includes the following:</p>
                  <br/>
                  <h3>The right to notice</h3>
                  <br/>
                  <p>You must be properly notified which categories of Personal Information are being collected and the purposes for which the Personal Information is being used.</p>
                  <br/>
                  <h3>The right to access / the right to request</h3>
                  <br/>
                  <p>The CCPA permits you to request and obtain from the Company information regarding the disclosure of your Personal Information that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party’s direct marketing purposes.</p>
                  <br/>
                  <h3>The right to say no to the sale of Personal Information</h3>
                  <br/>
                  <p>You also have the right to ask the Company not to sell your Personal Information to third parties. We do not sell your information.</p>
                  <br/>
                  <h3>The right to know about your Personal Information</h3>
                  <br/>
                  <p>You have the right to request and obtain from the Company information regarding the disclosure of the following:</p>
                  <br/>
                  <ul>
                    <li>The categories of Personal Information collected;</li>
                    <li>The sources from which the Personal Information was collected;</li>
                    <li>The business or commercial purpose for collecting or selling the Personal Information;</li>
                    <li>Categories of third parties with whom we share Personal Information; and</li>
                    <li>The specific pieces of Personal Information we collected about you</li>
                  </ul>
                  <br/>
                  <h3>The right to delete Personal Information</h3>
                  <br/>
                  <p>Except as required by HIPAA or other applicable law, you also have the right to request the deletion of your Personal Information that have been collected in the past 12 months.</p>
                  <br/>
                  <h3>The right not to be discriminated against</h3>
                  <br/>
                  <p>You have the right not to be discriminated against for exercising any of your Consumer's rights, including by:</p>
                  <br/>
                  <ul>
                    <li>Denying goods or services to you;</li>
                    <li>Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties;</li>
                    <li>Providing a different level or quality of goods or services to you;</li>
                    <li>Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                  </ul>
                  <br/>
                  <h2>How To Contact Us For Exercising Your Rights</h2>
                  <br/>
                  <p>In order to exercise any of your rights, you can -</p>
                  <br/>
                  <ul>
                    <li>Email us <a href="mailto:privacy@therapetic.org">privacy@therapetic.org</a>, or,</li>
                    <li>Call us at <a href="tel:+8772070561">(877) 207-0561</a></li>
                  </ul>
                  <br/>
                  <p>We will disclose and deliver the required information free of charge within 45 days of receiving your verified request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.</p>
                  <br/>
                  <h2>Definitions Used In This Policy</h2>
                  <br/>
                  <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                  <br/>
                  <p>“Account” means a unique account created for you to access our Service or parts of our Service.</p>
                  <br/>
                  <p>“Affiliate” means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                  <br/>
                  <p>“Business,” for the purpose of the California Consumer Privacy Act (“CCPA”), refers to the Company as the legal entity that collects Consumers' personal information and has the meaning set forth in CCPA § 1798.140(c).</p>
                  <br/>
                  <p>“Consumer,” for the purpose of the CCPA, means a natural person who is a California resident and has the meaning set forth in CCPA § 1798.140(g).</p>
                  <br/>
                  <p>“Cookies” are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.</p>
                  <br/>
                  <p>“Device” means any device that can access the Service such as a computer, a cell phone or a digital tablet.</p>
                  <br/>
                  <p>“Do Not Track” (“DNT”) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.</p>
                  <br/>
                  <p>“Personal Information” is any information that relates to an identified or identifiable individual. For the purposes of the CCPA, Personal Information means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you and has the meaning set forth in CCPA § 1798.140(o).</p>
                  <br/>
                  <p>“Sale,” for the purpose of the CCPA means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s Personal Information to another business or a third party for monetary or other valuable consideration and has the meaning set forth in CCPA § 1798.140(t).</p>
                  <br/>
                  <p>“Service” refers to the Application and Website, individually and collectively.</p>
                  <br/>
                  <p>“Service Provider” means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies and individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
                  <br/>
                  <p>“Third-party Social Media Service” refers to any website or any social network website through which a User can log in or create an account to use the Service.</p>
                  <br/>
                  <p>“Usage Data” refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                  <br/>
                  <p>“Website” refers to the Therapetic website, accessible at <a href="/">https://therapetic.org/</a></p>
                  <br/>
                  <p>“You” and “you” means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                  <br/>
                  <h2>What Information We Collect</h2>
                  <br/>
                  <p>We may collect information about you in a variety of ways. The information we may collect via the Service depends on the content and materials you use, and includes:</p>
                  <br/>
                  <h3>User Data</h3>
                  <br/>
                  <p>Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the Service, such as making a purchase, registering an account, sending feedback or responding to surveys. We also collect health related information that you share with us during the onboarding process and during your meeting with mental health professionals. This information is maintained under strict compliance with HIPAA.</p>
                  <br/>
                  <h3>Derivative Data</h3>
                  <br/>
                  <p>Information our servers automatically collect when you access the Service, such as your native actions that are integral to the Service, as well as other interactions with the Service via server log files.</p>
                  <br/>
                  <h3>Financial Data</h3>
                  <br/>
                  <p>Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) is processed via Stripe.</p>
                  <br/>
                  <h3>Public Information from Social Networks</h3>
                  <br/>
                  <p>User public information from social networking sites, such as Facebook, Instagram, Pinterest, or Twitter, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts. This information may also include the contact information of anyone you invite to use and/or join the Service.</p>
                  <br/>
                  <p>We may also collect the following information:</p>
                  <br/>
                  <ul>
                    <li>Geo-Location Information. We may request access to location-based information while you are using our location-based services. If you wish to change our access or permissions, you may do so in your device’s settings</li>
                    <li>Mobile Device Data. We may automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.</li>
                    <li> Push Notifications. We may request to send you push notifications regarding your account. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</li>
                  </ul>
                  <br/>
                  <h3>Data From Contests, Giveaways, and Surveys</h3>
                  <br/>
                  <p>Personal and other information you may provide when entering sweepstakes, games, tournaments, contests or giveaways and/or responding to surveys.</p>
                  <br/>
                  <h3>Information collected from other Sources</h3>
                  <br/>
                  <p>We may collect publicly available data from public databases, marketing partners, social media platforms, and other outside sources.</p>
                  <Table bordered hover>
                    <thead>
                    <tr>
                      <th>Category</th>
                      <th>Information we collect</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Identifiers</td>
                      <td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name.</td>
                    </tr>
                    <tr>
                      <td>
                        Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
                      <td>A name, signature, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories</td>
                    </tr>
                    <tr>
                      <td>Protected classification characteristics under California or federal law</td>
                      <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information), medical records and other data covered by HIPAA</td>
                    </tr>
                    <tr>
                      <td>Internet or other similar network activity</td>
                      <td>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement</td>
                    </tr>
                    <tr>
                      <td>Geolocation data</td>
                      <td>Physical location or movements</td>
                    </tr>
                    <tr>
                      <td>Inferences drawn from other personal information *</td>
                      <td>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
                    </tr>
                    </tbody>
                  </Table>
                  <p><stron>*These information are collected only as required for mental health evaluation</stron></p>
                  <br/>
                  <p>Personal information does not include:</p>
                  <br/>
                  <ul>
                    <li>Publicly available information from government records</li>
                    <li>De-identified or aggregated consumer information</li>
                    <li>
                      Information that is exempt from the CCPA such as:
                      <ul>
                        <li>Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
                        <li>Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
                      </ul>
                    </li>
                  </ul>
                  <br/>
                  <h2>How We Use The Information</h2>
                  <br/>
                  <ul>
                    <li>To provide and personalize the Therapetic service experience.</li>
                    <li>To evaluate and develop new features, technologies, and improvements to the services.</li>
                    <li>Comply with legal obligations.</li>
                    <li>Create and manage your account.</li>
                    <li>Fulfill and manage purchases, orders, payments, and other transactions.</li>
                    <li>Increase the efficiency and operation of the Service.</li>
                    <li>Notify you of updates to the Service.</li>
                    <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
                    <li>Process payments and refunds.</li>
                    <li>Request feedback and contact you about your use of the Service.</li>
                    <li>Resolve disputes and troubleshoot problems.</li>
                    <li>Respond to product and customer service requests.</li>
                    <li>Perform other business activities as needed, in accordance with this policy.</li>
                    <li>Design and administer marketing campaigns.</li>
                  </ul>
                  <br/>
                  <h2>Situations When We Might Disclose The Information</h2>
                  <br/>
                  <p>We may share information we have collected about you in certain situations, as follows:</p>
                  <br/>
                  <h3>Complying With Legal Obligations Or Protecting Rights</h3>
                  <br/>
                  <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>
                  <br/>
                  <h3>Third-Party Service Providers</h3>
                  <br/>
                  <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</p>
                  <br/>
                  <h3>Third-Party Advertisers, Affiliates or Business Partners</h3>
                  <br/>
                  <p><strong>We do not use third party advertisers on our website.</strong> We do not share your information with our affiliates or business partners.</p>
                  <br/>
                  <h3>Other Third Parties</h3>
                  <br/>
                  <p>We may share your information, in accordance with this policy, with advertisers and investors for the purpose of conducting general business analysis.</p>
                  <br/>
                  <h3>Merger or Acquisition</h3>
                  <br/>
                  <p>If we reorganize or sell all or a portion of our assets, undergo a merger, enter bankruptcy, or are acquired by another entity, we may transfer your information to the acquirer or successor entity. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.</p>
                  <br/>
                  <h2>How We Collect Information</h2>
                  <br/>
                  <h3>Tracking Technologies</h3>
                  <br/>
                  <p>We may use cookies, beacon, tags, pixels, scripts or other technologies on the Service to help customize the Service and improve your experience. When you access the Service, your personal information is not collected through the use of tracking technology.</p>
                  <br/>
                  <h3>Advertising</h3>
                  <br/>
                  <p>Additionally, we may use third-party software to implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us.</p>
                  <br/>
                  <h3>Website Analytics</h3>
                  <br/>
                  <p>We may also partner with selected third-party vendors to allow tracking technologies and remarketing services on the Service through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Service, determine the popularity of certain content, and better understand online activity.</p>
                  <br/>
                  <p>By accessing the Service, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions.</p>
                  <br/>
                  <p>We do not transfer personal information to these third-party vendors. Our service providers store this information on our behalf in a pseudonymized user profile and they are contractually forbidden to sell any of the data collected on our behalf.</p>
                  <br/>
                  <p>Hotjar and Lytics are technology services that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. For further details, please see the ‘about Hotjar’ section of <a
                    href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">Hotjar’s support site</a> and <a
                    href="https://learn.lytics.com/legal/privacy-policy">Lytics’s privacy policy</a>.</p>
                  <br/>
                  <h2>How We Protect The Information</h2>
                  <br/>
                  <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.</p>
                  <br/>
                  <h2>Our Policy For Minors</h2>
                  <br/>
                  <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data, we have collected from children under age 13, please contact us using the contact information provided above.</p>
                  <br/>
                  <h3>California Business and Professions Code § 22581</h3>
                  <br/>
                  <p>California residents under the age of 18 who are registered users of online sites, services, or applications have a right under California Business and Professions Code § 22581 to remove, or request and obtain removal of, content or information they have publicly posted. To remove content or information you have publicly posted, please contact us.</p>
                  <h2>Controls For Do-Not-Track Features</h2>
                  <br/>
                  <p>No uniform technology standard for recognizing and implementing Do-Not-Track (“DNT”) signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.</p>
                  <br/>
                  <h2>Do Not Sell My Personal Information</h2>
                  <br/>
                  <p><strong>We do not sell personal information.</strong></p>
                  <br/>
                  <p>The CCPA requires us to tell you that if you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA, you may do so by following the instructions below.</p>
                  <h2>Options You Have Regarding Your Information</h2>
                  <br/>
                  <h3>Account Information</h3>
                  <br/>
                  <p>You may at any time review or change the information in your account or terminate your account by: Contacting us using the contact information provided above.</p>
                  <br/>
                  <p>Except as required by The Health Insurance Portability and Accountability Act of 1996 (“HIPAA”) or other applicable law, upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
                  <br/>
                  <h3>Emails and Communications</h3>
                  <br/>
                  <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by contacting us using the contact information provided below.</p>
                  <br/>
                  <h3>Opting Out From Your Browser</h3>
                  <br/>
                  <p>You can opt out of receiving ads that are personalized as served by our Service Providers by clicking on the links below:</p>
                  <br/>
                  <ul>
                    <li><a href="https://optout.aboutads.info/">Digital Advertising Alliance Opt-Out Tool</a></li>
                    <li><a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out Plugin</a></li>
                    <li><a href="https://optout.networkadvertising.org/">Network Advertising Initiative Opt-Out Tool</a></li>
                    <li><a href="https://youradchoices.com/control">Your Ad Choices</a></li>
                  </ul>
                  <br/>
                  <h3>Mobile Devices</h3>
                  <br/>
                  <p>Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests.</p>
                  <br/>
                  <ul>
                    <li>"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</li>
                    <li>"Limit Ad Tracking" on iOS devices</li>
                    <li>You can also stop the collection of location information from your mobile device by changing the preferences on your mobile device.</li>
                  </ul>
                  <br/>
                  <h2>Information Shared In The Past 12 Months</h2>
                  <br/>
                  <p>In the past 12 months, we have had no changes to our privacy policy with regard to sharing information.</p>
                  <br/>
                  <h2>Third-Party Websites</h2>
                  <br/>
                  <p>The Service may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Service, any information you provide to these third parties is not covered by this Privacy Policy, and we do not guarantee the safety and privacy of your information.</p>
                  <br/>
                  <h2>Changes To This Privacy Policy</h2>
                  <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of this Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Service after the date such revised Privacy Policy is posted.</p>
                  <br/>
                  <br/>
                  <br/>
                  <p>Last Update: February 28, 2020</p>
                  <br/>
                  <p>Effective date: July 29, 2019</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Layout>
    )
  }
}


export default (props) => {
  const data = useStaticQuery(graphql`
    query PrivacyPolicy {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
      } 
    } 
  `)
  return (
    <PrivacyPolicy  {...props} data={data}/>
  )
};
